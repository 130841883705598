<template>
  <div v-if="form">
    <form
      ref="form"
      method="post"
      action="#"
    >
      <input
        type="hidden"
        name="reqInfo"
        :value="encStr"
      >
      <input
        type="hidden"
        name="retUrl"
        :value="retUrl"
      >
      <input
        type="hidden"
        name="verSion"
        :value="version"
      >
    </form>
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      cbaWindow: null,
      retUrl: null,
      encStr: null,
      reqNum: null,
      version: 1,
      result: {
        name: null,
        sex: null,
        birYMD: null,
        fgnGbn: null,
        di: null,
        ci1: null,
        ci2: null,
        civersion: null,
        reqNum: null,
        result: null,
        certDate: null,
        certGb: null,
        cellNo: null,
        cellCorp: null,
        addVar: null,
      },
      form: false
    }
  },
  computed: {
    ...mapGetters({
      verifyResult: 'util/getVerifyResult'
    }),
  },
  watch: {
    verifyResult() {
      if (this.verifyResult) this.getResult(this.verifyResult)
    }
  },
  created() {
    window.addEventListener('message', event => {
      if (event.origin !== window.location.origin) return
      if (!event.data.type || !event.data.retInfo) return
      if (event.data.type !== 'verificationResult') return

      this.getResult(event.data.retInfo)
    }, false)
  },
  methods: {
    getSeed() {
      axios.post('/fu/verification/seed-start', {
        srvNo: process.env.VUE_APP_SRV_NO,
      })
        .then(rs => {
          const { encStr, reqNum } = rs.data

          this.form = true

          this.encStr = encStr
          this.reqNum = reqNum
          this.retUrl = `21${window.location.origin}/#/verification/result`

          setTimeout(() => {
            this.openPCCWindow()
          }, 1)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResult(retInfo) {
      axios.post('/fu/verification/seed-result', {
        retInfo,
        reqNum: this.reqNum,
      })
        .then(rs => {
          this.result = rs.data

          this.$store.commit('util/setVerifyResult', null)

          this.$emit("result", rs.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    openPCCWindow() {
      this.cbaWindow = window.open('', 'PCCWindow', 'width=560, height=870, resizable=1, scrollbars=no, status=0, titlebar=0, toolbar=0, left=300, top=200')

      this.$refs.form.action = 'https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10.jsp'
      this.$refs.form.target = 'PCCWindow'
      this.$refs.form.submit()

      this.cbaWindow.onbeforeunload = () => {
        this.form = false
      }
    },
  },
}
</script>
