var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form
    ? _c("div", [
        _c("form", { ref: "form", attrs: { method: "post", action: "#" } }, [
          _c("input", {
            attrs: { type: "hidden", name: "reqInfo" },
            domProps: { value: _vm.encStr },
          }),
          _c("input", {
            attrs: { type: "hidden", name: "retUrl" },
            domProps: { value: _vm.retUrl },
          }),
          _c("input", {
            attrs: { type: "hidden", name: "verSion" },
            domProps: { value: _vm.version },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }