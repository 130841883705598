<template>
  <div>
    <div
      v-if="termsAgree === false"
      class="layer-your-self"
    >
      <button
        class="btn-close"
        @click="close()"
      >
        닫기
      </button>
      <p class="title">
        본인인증
      </p>

      <p class="s-title">
        개인정보 수집·이용 동의
      </p>

      <p class="txt">
        (주)유비케어(이하 '회사')는 닥터인포 서비스 운영을 위하여 아래와 같이 개인정보를<br>수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다.
      </p>

      <p class="s-title">
        ▶ 개인정보 수집·이용 내역 (본인 인증)
      </p>
      <p class="tb-title">
        [필수] 개인정보 수집 및 이용 동의
      </p>
      <div class="tb-lys">
        <table>
          <colgroup>
            <col style="width: 30%">
            <col style="width: 35%">
            <col style="width: 35%">
          </colgroup>
          <thead>
            <tr>
              <th>항목</th>
              <th>수집 및 이용목적</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>휴대 전화 번호,CI,<br>생년월일, 성별
              </th>
              <td>
                웹 세미나 서비스 제공,<br>포인트 교환, 내 정보 변경 시
              </td>
              <td>
                회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제
              </td>
            </tr>
            <tr>
              <th>성별</th>
              <td>
                진료과 별 맞춤형 서비스 제공<br>(맞춤형 설문 및 이용 통계)
              </td>
              <td>
                회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul class="red-tip">
        <li><span>※</span> 제공되는 인증 방식 : 휴대폰 (PASS)</li>
        <li><span>※</span> 위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다.<br>
          그러나 동의를 거부할 경우 닥터인포 서비스 이용에 제한을 받을 수 있습니다.</li>
      </ul>

      <p class="tip">
        더 자세한 내용에 대해서는 [<a @click="openPopup">개인정보처리방침</a>]을 참고하시기 바랍니다.
        <a @click="openPopup">https://fu.drinfo.co.kr/privacy</a>
      </p>

      <div class="checkbox-wrap">
        <input
          id="agree"
          v-model="verificationAgree"
          type="checkbox"
        >
        <label for="agree">개인정보 수집 및 이용에 동의합니다.</label>
      </div>

      <div class="flex-c">
        <button
          class="btn-change-layer"
          @click="saveVerification"
        >
          동의하기
        </button>
      </div>
    </div>

    <VerificationRequest
      ref="verification"
      @result="verifyResult"
    />

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'
import VerificationRequest from '@/views/pages/verification/VerificationRequest.vue'
import { mapGetters } from 'vuex'
import { setError } from '@/common/error/log'
import { getUserData, setUserData } from '@/auth/utils'

export default {
  components: {
    VerificationRequest,
  },
  data() {
    return {
      verificationAgree: false,
      verification: null,
      termsAgree: null
    }
  },
  computed: {
    ...mapGetters({
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      userName: 'infoData/getUserName',
    }),
  },
  methods: {
    open() {
      this.fetchVerification()
    },
    close() {
      this.termsAgree = null
    },
    openPopup() {
      window.open('https://fu.drinfo.co.kr/#/privacy', 'privacy', 'width=1450, height=870, resizable=no, scrollbars=no, status=0, titlebar=0, toolbar=0, left=200, top=100')
    },
    fetchVerification() {
      axios.post(`/fu/user/info/verification`, {
        loginId: this.shaEnLicenseNum
      })
        .then(rs => {
          const { verification, termsAgree } = rs.data

          this.verification = verification
          this.termsAgree = termsAgree

          if (this.termsAgree) this.$refs.verification.getSeed()
          else this.verificationAgree = false
        })
        .catch(() => {
        })
    },
    saveVerification() {
      if (this.verificationAgree === false) {
        this.showAlertDialog('개인정보 수집 및 이용 동의 약관에 동의해 주세요.')
      } else {
        axios.put(`/fu/user/terms/verification`, {
          loginId: this.shaEnLicenseNum,
        })
          .then(() => {
            this.termsAgree = true

            this.$refs.verification.getSeed()
          })
          .catch(() => {
            this.showAlertDialog('회원 정보 확인 중 오류가 발생했습니다.')
          })
      }
    },
    verifyResult(result) {
      if (this.verification) {
        axios.post(`/fu/user/match/verification`, {
          loginId: this.shaEnLicenseNum,
          identityDi: result.di,
        })
          .then(rs => {
            if (rs.data) this.updateVerification(result)
            else this.showAlertDialog('등록되어있는 인증 정보와 일치하지 않습니다.\n고객센터로 문의하시기 바랍니다.')
          })
          .catch(() => {
            this.showAlertDialog('회원 정보 확인 중 오류가 발생했습니다.')
          })
      } else if (this.checkValidation(result.name)) {
        this.updateVerification(result)
      } else this.showAlertDialog('의사랑 계정 정보와 일치하지 않습니다.\n고객센터로 문의하시기 바랍니다.')
    },

    checkValidation(resultName) {
      try {
        if (!resultName) return false

        const regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]+/g
        // 이름에서 한글만 추출하고 모든 공백은 제거
        const filterName = this.userName.replace(/\s+/g, '').match(regex).join('')

        return (resultName === filterName)
      } catch (err) {
        // 확인된 사항으로 match(regex)에서 치환 후 값이 null일 때 join('')하려하여 발생
        setError(`본인인증 이름 확인 시 오류 발생 (${this.userName}:${resultName}) (${err.message})`, 'PopupVerification.vue')
        return false
      }
    },

    updateVerification(result) {
      if (!result || !result.cellNo || !result.ci1 || !result.di) {
        this.showAlertDialog('인증 결과 정보를 불러오지 못하였습니다.\n본인 인증을 다시 진행해 주세요.')
        return
      }

      const savePhoneNum = result.cellNo.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
      axios.put(`/fu/user/verification`, {
        userLoginId: this.shaEnLicenseNum,
        phoneNumber: savePhoneNum,
        birthDate: result.birYMD,
        gender: result.sex === 'M' ? 1 : 2,
        identityCi: result.ci1,
        identityDi: result.di
      })
        .then(() => {
          const user = getUserData()

          if (user) {
            user.verify = true
            user.phoneNumber = savePhoneNum

            setUserData(user)
          }

          this.$emit("result", result)
        })
        .catch(() => {
          this.showAlertDialog('인증 정보 업데이트 중 오류가 발생했습니다.')
        })
    }
  }
}
</script>
