var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.termsAgree === false
        ? _c("div", { staticClass: "layer-your-self" }, [
            _c(
              "button",
              {
                staticClass: "btn-close",
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v(" 닫기 ")]
            ),
            _c("p", { staticClass: "title" }, [_vm._v(" 본인인증 ")]),
            _c("p", { staticClass: "s-title" }, [
              _vm._v(" 개인정보 수집·이용 동의 "),
            ]),
            _vm._m(0),
            _c("p", { staticClass: "s-title" }, [
              _vm._v(" ▶ 개인정보 수집·이용 내역 (본인 인증) "),
            ]),
            _c("p", { staticClass: "tb-title" }, [
              _vm._v(" [필수] 개인정보 수집 및 이용 동의 "),
            ]),
            _vm._m(1),
            _vm._m(2),
            _c("p", { staticClass: "tip" }, [
              _vm._v(" 더 자세한 내용에 대해서는 ["),
              _c("a", { on: { click: _vm.openPopup } }, [
                _vm._v("개인정보처리방침"),
              ]),
              _vm._v("]을 참고하시기 바랍니다. "),
              _c("a", { on: { click: _vm.openPopup } }, [
                _vm._v("https://fu.drinfo.co.kr/privacy"),
              ]),
            ]),
            _c("div", { staticClass: "checkbox-wrap" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.verificationAgree,
                    expression: "verificationAgree",
                  },
                ],
                attrs: { id: "agree", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.verificationAgree)
                    ? _vm._i(_vm.verificationAgree, null) > -1
                    : _vm.verificationAgree,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.verificationAgree,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.verificationAgree = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.verificationAgree = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.verificationAgree = $$c
                    }
                  },
                },
              }),
              _c("label", { attrs: { for: "agree" } }, [
                _vm._v("개인정보 수집 및 이용에 동의합니다."),
              ]),
            ]),
            _c("div", { staticClass: "flex-c" }, [
              _c(
                "button",
                {
                  staticClass: "btn-change-layer",
                  on: { click: _vm.saveVerification },
                },
                [_vm._v(" 동의하기 ")]
              ),
            ]),
          ])
        : _vm._e(),
      _c("VerificationRequest", {
        ref: "verification",
        on: { result: _vm.verifyResult },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "txt" }, [
      _vm._v(
        " (주)유비케어(이하 '회사')는 닥터인포 서비스 운영을 위하여 아래와 같이 개인정보를"
      ),
      _c("br"),
      _vm._v(
        "수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다. "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tb-lys" }, [
      _c("table", [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "30%" } }),
          _c("col", { staticStyle: { width: "35%" } }),
          _c("col", { staticStyle: { width: "35%" } }),
        ]),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("항목")]),
            _c("th", [_vm._v("수집 및 이용목적")]),
            _c("th", [_vm._v("보유 및 이용기간")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("th", [
              _vm._v("휴대 전화 번호,CI,"),
              _c("br"),
              _vm._v("생년월일, 성별 "),
            ]),
            _c("td", [
              _vm._v(" 웹 세미나 서비스 제공,"),
              _c("br"),
              _vm._v("포인트 교환, 내 정보 변경 시 "),
            ]),
            _c("td", [
              _vm._v(" 회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제 "),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("성별")]),
            _c("td", [
              _vm._v(" 진료과 별 맞춤형 서비스 제공"),
              _c("br"),
              _vm._v("(맞춤형 설문 및 이용 통계) "),
            ]),
            _c("td", [
              _vm._v(" 회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제 "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "red-tip" }, [
      _c("li", [
        _c("span", [_vm._v("※")]),
        _vm._v(" 제공되는 인증 방식 : 휴대폰 (PASS)"),
      ]),
      _c("li", [
        _c("span", [_vm._v("※")]),
        _vm._v(
          " 위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다."
        ),
        _c("br"),
        _vm._v(
          " 그러나 동의를 거부할 경우 닥터인포 서비스 이용에 제한을 받을 수 있습니다."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }